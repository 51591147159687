@tailwind base;
@tailwind components;
@tailwind utilities;

/* ::-webkit-scrollbar { */
/*   display: none; */
/* } */

.react-datepicker__input-container > input {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: block;
  width: 100%;
  padding: 8px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: inherit;
  line-height: inherit;
  border: 1px solid;
  border-radius: 4px;
  color: inherit;
  background-color: transparent;
  font-family: Inter;
  border-color: #cfcfd3;
  background-color: #fff;
}

.react-datepicker__input-container > input:focus {
  border-color: #000;
  outline: none;
  box-shadow: 0 0 0 2px #000;
}

.react-datepicker-wrapper {
  width: 100%;
}

[data-reach-dialog-overlay] {
  z-index: 99999;
}

* {
  box-sizing: border-box;
}
@import url("https://fonts.googleapis.com/css?family=Inter:regular,bold,italic&subset=latin,latin-ext");

@font-face {
  font-family: "Inconsolata";
  src: url("./assets/fonts/Inconsolata-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inconsolata";
  src: url("./assets/fonts/Inconsolata-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Reforma1918";
  src: url("./assets/fonts/Reforma1918-Blanca.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Reforma1918";
  src: url("./assets/fonts/Reforma1918-BlancaItalica.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Reforma1918";
  src: url("./assets/fonts/Reforma1918-Gris.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Reforma1918";
  src: url("./assets/fonts/Reforma1918-GrisItalica.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Reforma2018";
  src: url("./assets/fonts/Reforma2018-Blanca.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Reforma2018";
  src: url("./assets/fonts/Reforma2018-BlancaItalica.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Reforma2018";
  src: url("./assets/fonts/Reforma2018-Gris.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Reforma2018";
  src: url("./assets/fonts/Reforma2018-GrisItalica.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Apercu";
  src: url("./assets/fonts/Apercu/Apercu-Bold-Italic.otf");
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("./assets/fonts/Apercu/Apercu-Bold.otf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("./assets/fonts/Apercu/Apercu-Italic.otf");
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("./assets/fonts/Apercu/Apercu-Light-Italic.otf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("./assets/fonts/Apercu/Apercu-Light.otf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("./assets/fonts/Apercu/Apercu-Medium-Italic.otf");
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("./assets/fonts/Apercu/Apercu-Medium.otf");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Apercu";
  src: url("./assets/fonts/Apercu/Apercu-Regular.otf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
